import React from 'react';
import logo from '../../Assets/images/Logo2.png';
import logoXpc from '../../Assets/images/logo-X-blanc-48px.png'
import logoFacebookpc from '../../Assets/images/facebook-48px.png'
import logoLinkedinpc from '../../Assets/images/linkedin-48px.png'

export default function Header() {
    return(
        <div className='App-header'>
            <img src={logo} className="App-logo" alt="logo-favicon" />
            <p className='slogan'>
               Créateur de sites Web et Applications Mobiles
            </p>
            <div className='bloc-reseau'>
                <div className='facebookLink'>
                    <a href="https://www.facebook.com/profile.php?id=61558926789384">
                        <img src={logoFacebookpc} className='logo' alt="logo-facebook" width="24px" height="24px"/>
                    </a>
                </div>
                <div className='twitterLink'>
                    <a href="https://twitter.com/webSite1Creator">
                        <img src={logoXpc} className='logo' alt="logo-twitter" width="48px" height="43px"/>
                    </a>
                </div>
                <div className='LinkedinLink'>
                    <a href="https://www.linkedin.com/in/s%C3%A9bastien-goris-0960ab306/">
                        <img src={logoLinkedinpc} className='logo' alt="logo-Linkedin" width="24px" height="24px"/>
                    </a>
                </div>
            </div>
        </div>
    )
}