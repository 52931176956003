import React, { useState, useEffect } from 'react';
import Image1 from "../../Assets/images/blog.webp";
import Image2 from "../../Assets/images/ecommerce.webp";
import Image3 from "../../Assets/images/application.webp";
import Image4 from "../../Assets/images/api.webp";
import Image5 from "../../Assets/images/e-commerce.webp";

const SlideShow = () => {
  const [images] = useState([
    Image1, 
    Image2,
    Image3,
    Image4,
    Image5,
  ]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 5000);
    
    return () => clearInterval(intervalId);
  }, [currentImageIndex, images.length]);

  return (
    <div className="image-carousel">
      <img src={images[currentImageIndex]} alt={`Images ${currentImageIndex + 1}`} />
    </div>
  );
};

export default SlideShow ;