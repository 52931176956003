import React from 'react'
import { Helmet } from 'react-helmet';
import Navigation from '../Components/Navigation/Navigation'
import SlideShow from '../Components/SlideShow/SlideShow'
import {NavLink} from 'react-router-dom'

export default function Home() {
  return (
    <div className='homePage-centrale'>
      <Helmet>
        <title>Website-Creator - Agence Marketing à La Gaude | Audit SEO </title>
        <meta name="description" content="Consultant marketing digital et Développeur Web à La Gaude, Website-Creator vous accompagne dans la création de vos sites internet et référencement SEO" />
        <link rel="canonical" href="https://website-creator.fr/"></link>
        <meta property="og:title" content="Website-Creator - Agence Marketing à La Gaude | Audit SEO"></meta>
        <meta property="og:description" content="Consultant marketing digital et Développeur Web à La Gaude, Website-Creator vous accompagne dans la création de vos sites internet et référencement SEO"></meta>
        <meta property="og:url" content="https://website-creator.fr/"></meta>
      </Helmet>
        <Navigation/>
        <div className='blocHome'>
          <div className='Intro'>
            <h1 className='animated-text'>Besoin d'un site internet </h1>
            <p>Chez Website Creator, nous vous proposons de vous accompagner dans la conception de votre projet web. Du simple site vitrine à un site de vente en ligne e-commerce ou une application mobile, nos compétences en développement feront en sorte que votre site soit unique, élégant et le plus visible possible. <br /> 
            Vous n'avez pas encore de site internet ! Pas de soucis, nous vous conseillerons quel type de site web il vous faut en fonction de votre activité. <br />
            Vous avez déjà un site internet mais vous trouvez qu'il n'est pas bien référencé ! Nous pouvons analyser votre site web et l'optimiser afin de le faire remonter dans les résultats de recherche. <br />
            Nos sites sont entièrement codés à la main (et oui nous ne codons pas avec les pieds), et sont testés au niveau performance, accessibilté et SEO pour être approuvés sans erreurs sur les différents navigateurs. <br />
            Nous pouvons également réaliser un suivi de votre site internet. Une fois fini, il vous faudra batailler pour rester visible. Nous vous proposons pour cela une forme contrat d'entretien dans lequel nous analysons périodiquement votre site, mettons à jour photos et texte et alimentons les réseaux sociaux en rapport avec le site et votre activité.</p>
            <h2> 
              <NavLink to="/offers" className='liens'>Découvrez nos offres</NavLink>
            </h2>
            <p>Un interlocuteur unique, directement en contact avec la personne qui va coder votre site web, toutes vos exigences en terme de design seront appliquées. Un premier entretien permettra d'étudier votre projet et vos idées les plus farfelues, ainsi nous pourrons établir une maquette et une fourchette de prix pour votre site internet. N'hésitez pas à nous contacter pour une étude et un devis gratuit</p>
            <h2> 
              <NavLink to="/contact" className='liens'>Contactez-nous</NavLink>
            </h2>
          </div>
          <div className='SlidePresentation'>
            <div className='slide'>
              <SlideShow/>
            </div>
            <div className='homePresentation'>
              <p>Qui suis-je ?<br /> 
              Sébastien passionné d'informatique et de jeux vidéos, c'est tout naturellement que je me suis dirigé vers l'apprentissage de différents langages de code informatique. <br /> 
              Html et CSS pour commencer, javascript et React sont vite devenus mes langages préférés. <br />
              Devenir développeur web était une évidence et vous aider aujourd'hui à augmenter la croissance de votre activité, en créant pour votre société plus de visibilité me réjouit d'avance. <br />
              La première chose qu'une personne fait lorsqu'elle a besoin d'un service, d'une entreprise ou d'une information, c'est une recherche Google et mon combat est de faire en sorte que votre entreprise soit placée en première page. <br />
              Faites confiance à un passionné et confiez-moi votre projet !
              </p>
            </div>
          </div>
        </div>
    </div>
  )
}