import React from 'react'
import Navigation from '../Components/Navigation/Navigation'

export default function Mentions() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
    
    return (
        <div className='mentions'>
            <Navigation/>
            <h2>Mentions Légales</h2>
            <p>1. Présentation du site :<br/>
En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site www.websitecreator.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :<br/>
Propriétaire : Sébastien Goris – 411 route de Saint-Jeannet 06610 La Gaude<br/>
Créateur : Sébastien Goris <br/>
Hébergement O2SWITCH Chemin des Pardiaux 63000 Clermont-Ferrand - France<br/>

2. Conditions générales d'utilisation du site et des services proposés :<br/>
L'utilisation du site www.websitecreator.fr implique l'acceptation pleine et entière des conditions générales d'utilisation ci-après décrites. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs du site www.websitecreator.fr sont donc invités à les consulter de manière régulière.<br/>
Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par www.websitecreator.fr, qui s'efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l'intervention.<br/>
Le site www.websitecreator.fr est mis à jour régulièrement par Sébastien Goris. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s'imposent néanmoins à l'utilisateur qui est invité à s'y référer le plus souvent possible afin d'en prendre connaissance.<br/>

3. Description des services fournis :<br/>
Le site www.websitecreator.fr a pour objet de fournir une information concernant l'ensemble des activités de la société.<br/>
Sébastien Goris s'efforce de fournir sur le site www.websitecreator.fr des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.<br/>
Tous les informations indiquées sur le site www.websitecreator.fr sont données à titre indicatif, et sont susceptibles d'évoluer. Par ailleurs, les renseignements figurant sur le site www.websitecreator.fr ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.<br/>

4. Limitations contractuelles sur les données techniques.<br/>
Le site utilise la technologie React.<br/>

Le site Internet ne pourra être tenu responsable de dommages matériels liés à l'utilisation du site. De plus, l'utilisateur du site s'engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.<br/>

5. Propriété intellectuelle et contrefaçons :<br/>
Sébastien Goris est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site, notamment les textes, graphismes, logo, icônes, logiciels.<br/>
Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : Sébastien Goris.<br/>
Toute exploitation non autorisée du site ou de l'un quelconque des éléments qu'il contient sera considérée comme constitutive d'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.<br/>

6. Limitations de responsabilité :
Sébastien Goris ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site www.websitecreator.fr, et résultant soit de l'utilisation d'un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l'apparition d'un bug ou d'une incompatibilité.<br/>
Sébastien Goris ne pourra également être tenu responsable des dommages indirects (tels par exemple qu'une perte de marché ou perte d'une chance) consécutifs à l'utilisation du site www.websitecreator.fr.<br/>

7. Gestion des données personnelles :<br/>
En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.À l'occasion de l'utilisation du site www.websitecreator.fr, peuvent êtres recueillies : l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au site www.websitecreator.fr, le fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur.<br/>
En tout état de cause Sébastien Goris ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site www.websitecreator.fr. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site www.websitecreator.fr l'obligation ou non de fournir ces informations.<br/>
Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, tout utilisateur dispose d'un droit d'accès, de rectification et d'opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d'une copie du titre d'identité avec signature du titulaire de la pièce, en précisant l'adresse à laquelle la réponse doit être envoyée.<br/>
Aucune information personnelle de l'utilisateur du site www.websitecreator.fr n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de Website-Creator et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation . et de modification des données vis à vis de l'utilisateur du site www.websitecreator.fr.<br/>
Le site n'est pas déclaré à la CNIL car il ne recueille pas d'informations personnelles.<br/>
Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.<br/>

8. Droit applicable et attribution de juridiction :<br/>
Tout litige en relation avec l'utilisation du site www.websitecreator.fr est soumis aux tribunaux francais compétents statuant selon le droit français.<br/>

9. Les principales lois concernées :<br/>
Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés.<br/>
Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.<br/>
</p>

<div className='mentions'>
      {/* ... Votre contenu de page ... */}

      {/* Bouton pour retourner en haut */}
      <button onClick={scrollToTop}>Retour en Haut de Page</button>
    </div>
</div>

    )
    
    
}