import React from 'react';
import {NavLink} from 'react-router-dom'

export default function Navigation() {
    return (
        <div className='navigation'>
            <NavLink to="/" className='home'>Accueil</NavLink>
            <NavLink to="/offers" className='offres'>Nos offres</NavLink>
            <NavLink to="/contact" className='contact'>Nous contacter</NavLink>
        </div>
    )
}