import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import Home from './Pages/Home';
import Offers from "./Pages/Offers";
import Contact from "./Pages/Contact";
import ErrorPage from "./Pages/ErrorPages";
import Mentions from "./Pages/Mentions";

export const router = createBrowserRouter([ 
    { path: '/', 
        element: <App/>, 
        errorElement:<ErrorPage/>,
        children: [ 
            { 
              path: '', 
              element: <Home/>
            },
            {
              path: 'offers',
              element: <Offers/>,
            }, 
            {
              path: 'contact',
              element: <Contact/>
            },
            {
              path: 'mentions',
              element: <Mentions/>
          },
        ],

    },
  ]);







