import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
  const [state, handleSubmit] = useForm("mnqevkrl");
  if (state.succeeded) {
      return <p>Message envoyé avec succès!</p>;
  }
  return (
      <form className='form' onSubmit={handleSubmit}>
      <div className='name'>
        <label htmlFor="name" className='nameLabel'>
           Nom
        </label>
        <input className='nameInput'
          id="name"
          type="name" 
          name="name"
        />
      </div>
      <div className='mail'>
        <label htmlFor="email" className='mailLabel'>
          Adresse Email
        </label>
        <input className='mailInput'
          id="email"
          type="email" 
          name="email"
        />
        <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
        />
      </div>
      <div className='message'>
        <label htmlFor="message" className='messageLabel'>
          Votre message
      </label>
        <textarea className='messageArea'
          id="message"
          name="message"
        />
        <ValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
        />
      </div>
      <button className='button' type="submit" disabled={state.submitting}>
        Envoyez votre message
      </button>
    </form>
  );
}
function App() {
  return (
    <ContactForm />
  );
}
export default App;
