import React from 'react'
import { Helmet } from 'react-helmet';
import Navigation from '../Components/Navigation/Navigation'
import ContactForm from '../Components/ContactForm/ContactForm'



export default function Contact() {
    return (
        <div className='contactPage'>
            <Helmet>
                <title>Nous contacter - WebsiteCreator</title>
                <meta name="description" content="Contacter l'agence Website-Creator située à La Gaude, par email ou par téléphone. Du lundi au vendredi de 9h à 18h" />
                <link rel="canonical" href="https://website-creator.fr/contact"></link>
                <meta property="og:title" content="Nous contacter - WebsiteCreator"></meta>
                <meta property="og:description" content="Contacter l'agence Website-Creator située à La Gaude, par email ou par téléphone. Du lundi au vendredi de 9h à 18h"></meta>
                <meta property="og:url" content="https://website-creator.fr/contact"></meta>
            </Helmet>
            <Navigation/>
            <div className='Form'>
                <h1 className='animated-text'>
                    Besoin d'un devis gratuit  <br />
                    Contactez-Nous
                </h1>
                <div className='contactBloc'>
                    <div className='mesContacts'>
                        <div className='telText'>
                            <h2>Par téléphone pour un premier échange </h2>
                            <p>En contact direct avec la personne qui va coder votre site internet, vous aurez toutes les réponses aux questions que vous vous posez sur comment nous allons vous aider à réaliser votre projet de site Internet. Nous étudions votre projet et vous proposons une solution en prenant compte de votre budget et de vos envies</p>
                        </div>
                        <div className='Téléphone'>
                            <span>06-22-78-62-26</span>
                        </div>
                        <div className='mailText'>
                            <h2>Par mail</h2>
                            <p>Vous souhaitez nous envoyez une maquette, des photos, votre logo ou tout élément nous permettant d'alimenter votre page web, contactez Website Creator directement par mail</p>
                        </div>
                        <div className='mailMail'>
                            <span>website1creator@gmail.com</span>
                        </div>
                    </div>
                    <div className='contactForm'>
                        <div className='messageText'>
                            <h2>Par message </h2>
                            <p>Vous souhaitez une réponse rapide à une question simple sur notre méthode de travail pour réaliser un site internet, nos tarifs de site web, remplissez directement notre formulaire de contact ci-dessous</p>
                        </div>
                        <div className='Mail'>
                            { <ContactForm /> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
 

      