import React, { useEffect, useState} from 'react'
import Navigation from '../Components/Navigation/Navigation'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LogoGoogle from '../../src/Assets/images/logo-google.jpeg'
import Recherche from '../../src/Assets/images/recherche.jpeg'
import Cafe from '../../src/Assets/images/café.jpeg'

export default function Offers() {
    const navigate = useNavigate();
    return (
        <div className='Offers'>
            <Helmet>
                <title>Nos offres et tarifs site internet - Website-Creator</title>
                <meta name="description" content="Découvrez nos tarifs pour la création d'un site internet, le prix d'un e-commerce et nos réalisations en tant que consultant marketing" />
                <link rel="canonical" href="https://website-creator.fr/offers"></link>
                <meta property="og:title" content="Nos offres et tarifs site internet - Website-Creator"></meta>
                <meta property="og:description" content="Découvrez nos tarifs pour la création d'un site internet, le prix d'un e-commerce et nos réalisations en tant que consultant marketing"></meta>
                <meta property="og:url" content="https://website-creator.fr/offers"></meta>
            </Helmet>
            <Navigation/>
            <div className='offersPage-centrale'>
                <h1 className="animated-text">Quels tarifs pour un site internet</h1>
                <div className='offersBloc'>
                    <div className='site-vitrine'>
                        <h2>SITE INTERNET PERSONNALISÉ</h2>
                        <p>
                            - Besoin d'un site vitrine, Website Creator vos accompagne dans vore projet<br/>
                            <br />
                            - Un premier rendez-vous autour d'un café pour étudier votre activité et vous proposer quelques idées afin de mettre en avant votre entreprise<br/>
                            <img className='cafe' src={Cafe} alt="café" width="160px" height="160px"/><br />
                            - Codage dans les règles avec les langages HTML5, CSS3, JAVASCRIPT et REACT<br/>
                            <br />
                            - Tests W3C pour vérifier qu'il n 'y ai aucune erreur de codage <br />
                            <br />
                            - Référencement SEO : Le site web est entièrement codé à la main et de manière optimisé pour répondre aux algorithmes des différents navigateurs <br/>
                            <br />
                            - Validation du projet et modifications si nécessaires pour satisfaire au mieux les besoins du client <br/>
                            <br />
                            - Mise en ligne su site internet avec création du (ou des) nom(s) de domaine, hébergement adapté  <br/>
                            <br />
                            - Suivi de l'évolution et du traffic généré sur le site et possibilité d'alimenter les réseaux sociaux associés.
                        </p>
                        <span>à partir de 599€ *</span>
                        <p className='contactLink' onClick={() => navigate("/Contact")}>* Nous contacter pour un devis personnalisé</p>
                    </div>
                    <div className='site-ecommerce'>
                        <h2>SITE E-COMMERCE</h2>
                        <p>Le commerce en ligne vous attire ? <br/>
                        Vous souhaitez développer une activité grace au e-commerce ?<br/>
                        Ce site internet vous permet de mettre en avant votre commerce physique, de toucher une cible plus large et de vendre vos produits ou services de jour comme de nuit.
                        </p>
                        <span>à partir de 1699€ *</span>
                        <p className='contactLink' onClick={() => navigate("/Contact")}>* Nous contacter pour un devis personnalisé</p>
                    </div>
                    <div className='App-mobile'>
                        <h2>RÉFÉRENCEMENT SEO</h2>
                        <p>Le référencement naturel est la meilleure solution pour gagner en visibilité et faire apparaître votre entreprise dans les premiers résultats de recherche Google.</p>
                        <img className='logoGoogle-animated' src={LogoGoogle} alt="logo google" width="128px" height="128px"/>
                        <p>Nous nous occupons de l'optimisation de sites internet existants. <br /> 
                        Après un audit technique de votre site web, l'étude de mots clés en rapport avec votre activité et une veille concurentielle, </p>
                        <img className='detective-animated' src={Recherche} alt="détective" width="128px" height="128px" />
                        <p>nous établissons un rapport d'optimisation et allons retravailler les points nécessaires que ce soit l'architecture du site web, les métadonnées, le maillage interne et externe, <br />
                        et aussi vous proposer de créer du contenu marketing, alimenter vos réseaux sociaux et étudier l'évolution du traffic sur votre site internet.
                        </p>
                        <span>45€ de l'heure *</span>
                        <p className='contactLink' onClick={() => navigate("/Contact")}>* Nous contacter pour un devis personnalisé</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
